import React from 'react';
import ModalDeCorrecoesContent from './ModalDeCorrecoesContent';
import { ModalDeCorrecoesContextProvider } from './ModalDeCorrecoesContext';
import { ModalProps } from './protocols';

export const ModalDeCorrecoes: React.FC<ModalProps> = (props) => {
  return (
    <ModalDeCorrecoesContextProvider {...props}>
      <ModalDeCorrecoesContent />
    </ModalDeCorrecoesContextProvider>
  );
};
