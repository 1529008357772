import styled from 'styled-components';

export const Card = styled.div`
  .modal-acoes {
    height: 70px;

    cursor: pointer;

    & + .modal-acoes {
      border-top: 1px solid #ededed;
    }
    h5 {
      font-size: 18px;
      color: #474747;
    }
    small {
      font-size: 13px;
    }
    svg {
      color: ${(props) => props.theme.colors.nav} !important;
    }
  }
`;
