/* eslint-disable no-alert */
import React from 'react';
import { Modal, Button, Tabs, Tab, Spinner } from 'react-bootstrap';
import { ActionModal } from './styles';

import { useModalDeCorrecoes } from './ModalDeCorrecoesContext';
import { useValidadorXML } from '../../ValidadorContext';
import {
  AlterarCFOP,
  AlterarNCM,
  CodigoDeBarras,
  Home,
  SubstituirProduto,
} from './screens';
import { NovoCFOP, NovoNCM } from './protocols';
import { revalidaItem } from '../../Funcoes/Correcao';
import { ProductDataToTable } from '../../protocols';

const ModalDeCorrecoesContent: React.FC = () => {
  const {
    modalTab,
    setModalTab,
    dadosItemACorrigir,
    resetModalOnClose,
    confirmarAlteracao,
    funcaoConfirmar,
    loading,
    setFiltroOrigem,
    setFiltroStatus,
    handleFiltrosOrigem,
    handleFiltrosStatus,
    setNovoNCM,
    setNovoCFOP,
    setShowData,
    listaDeStatus,
    handleResponseData,
  } = useModalDeCorrecoes();

  const {
    showModalDeCorrecoes,
    setShowModalDeCorrecoes,
    parametrosValidacao,
    dadosDaCapa,
    dadosErpDoFornecedor,
    onSetItemACorrigir,
  } = useValidadorXML();

  return (
    <ActionModal
      show={showModalDeCorrecoes}
      onHide={() => {
        setFiltroOrigem('TODOS');
        setFiltroStatus('TODOS');
        handleFiltrosOrigem('TODOS');
        handleFiltrosStatus('TODOS');
        setShowModalDeCorrecoes(false);
        resetModalOnClose();
        onSetItemACorrigir({} as ProductDataToTable);
        setNovoNCM({} as NovoNCM)
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // size="xl"
      backdrop={!loading || 'static'}
      dialogClassName="custom-modal"
    >
      <Modal.Header>
        <Modal.Title>{`Nº Item: ${dadosItemACorrigir.index} - cEAN: ${dadosItemACorrigir.cEAN} - xProd: ${dadosItemACorrigir.xProd}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="controlled-tab-status"
          activeKey={modalTab}
          onSelect={(k) => {
            setModalTab(`${k}`);
          }}
        >
          {/* 951,78 */}
          <Tab eventKey="filtros" title="teste">
            <Home />
          </Tab>
          <Tab eventKey="substituirProduto" title="">
            <SubstituirProduto />
          </Tab>
          <Tab eventKey="adicionarProdutoNoMix" title="">
            Adicionar Produto no Mix
          </Tab>
          <Tab eventKey="retornarEmLinha" title="">
            Retornar em linha
          </Tab>
          <Tab eventKey="atualizarUnCompraERef" title="">
            Atualizar Unidade de Compra ou Referência
          </Tab>
          <Tab eventKey="atualizarCodigoDeBarras" title="">
            <CodigoDeBarras />
          </Tab>
          <Tab eventKey="alterarNCM" title="">
            <AlterarNCM />
          </Tab>
          <Tab eventKey="alterarCFOP" title="">
            <AlterarCFOP />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            setNovoNCM({} as NovoNCM);
            setNovoCFOP({} as NovoCFOP);
            setShowData('');
            if (modalTab !== 'filtros') {
              setModalTab('filtros');
            } else {
              setFiltroOrigem('TODOS');
              setFiltroStatus('TODOS');
              handleFiltrosOrigem('TODOS');
              handleFiltrosStatus('TODOS');
              setShowModalDeCorrecoes(false);
              resetModalOnClose();
              onSetItemACorrigir({} as ProductDataToTable);
            }
          }}
        >
          {modalTab !== 'filtros' ? 'Voltar' : 'Fechar'}
        </Button>
        {modalTab !== 'filtros' && (
          <Button
            variant="primary"
            onClick={async () => {
              confirmarAlteracao(funcaoConfirmar);

              if (loading) {
                const response = await revalidaItem({
                  dadosItemACorrigir,
                  listaDeStatus,
                  parametrosValidacao,
                  dadosDaCapa,
                  dadosErpDoFornecedor,
                });

                await handleResponseData({
                  response,
                  campo: 'nenhum',
                  origem: 'nenhum',
                });
              }

              if (
                (modalTab !== 'filtros' && loading) ||
                (modalTab === 'atualizarCodigoDeBarras' && !loading)
              ) {
                setModalTab('filtros');
              }
            }}
            disabled={loading}
            style={{
              display: 'flex',
              height: '38px',
              width: '94px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              'Confirmar'
            )}
          </Button>
        )}
      </Modal.Footer>
    </ActionModal>
  );
};

export default ModalDeCorrecoesContent;
