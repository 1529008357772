import React from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import { Card } from './styles';

import CircularProgress from '@material-ui/core/CircularProgress';

interface CardCorrecaoProps {
  Icon: IconType;
  titulo: string;
  descricao: string;
  executando?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const CardCorrecao: React.FC<CardCorrecaoProps> = (props) => {
  const { Icon, titulo, descricao, executando = false, onClick } = props;
  return (
    <Card
      className="col-12 d-flex flex-row align-items-center modal-acoes"
      onClick={(e) => {
        if (executando === false) onClick(e);
      }}
    >
      <div>
        {!executando ? (
          <Icon size="30px" />
        ) : (
          <CircularProgress size={27} style={{ color: '#ffffff' }} />
        )}
      </div>
      <div className="ps-2 w-100 d-flex align-items-start flex-column justify-content-around">
        <div className="d-flex align-items-end">
          <h5 className="m-0">{titulo}</h5>
        </div>
        <div className="d-flex align-items-start">
          <small className="m-0">{descricao}</small>
        </div>
      </div>
      <div>
        <AiOutlineRight size="20px" />
      </div>
    </Card>
  );
};
