import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { ProductDataToTable, statusValidation } from '../../protocols';

import { NovoCFOP, NovoNCM, NovoProduto, ResponseDataProps } from './protocols';

import {
  ExecStatusProps,
  ListaDeGtinsProps,
  ListaDeNCMsProps,
  ListaDeCFOPsProps,
} from './protocols';
import { useValidadorXML } from '../../ValidadorContext';
import {
  atualizarUnidadeCompraEReferencia,
  substituirProduto,
} from '../../Funcoes/Correcao';
import { toast } from 'react-toastify';
import revalidaItemUnico from '../../Funcoes/Correcao/revalidaItemUnico';
import api from '~/services/api';

type ModalDeCorrecoesContextData = {
  filtroOrigem: string;
  setFiltroOrigem: React.Dispatch<React.SetStateAction<string>>;
  filtroStatus: string;
  setFiltroStatus: React.Dispatch<React.SetStateAction<string>>;
  dadosItemACorrigir: ProductDataToTable;
  setDadosItemACorrigir: React.Dispatch<
    React.SetStateAction<ProductDataToTable>
  >;
  listaDeStatus: number[];
  setListaDeStatus: React.Dispatch<React.SetStateAction<number[]>>;
  executando: ExecStatusProps;
  setExecutando: React.Dispatch<React.SetStateAction<ExecStatusProps>>;
  modalTab: string;
  setModalTab: React.Dispatch<React.SetStateAction<string>>;
  listaDeGtins: ListaDeGtinsProps[];
  setListaDeGtins: React.Dispatch<React.SetStateAction<ListaDeGtinsProps[]>>;
  listaDeNCM: ListaDeNCMsProps[];
  setListaDeNCM: React.Dispatch<React.SetStateAction<ListaDeNCMsProps[]>>;
  funcaoConfirmar: string;
  setFuncaoConfirmar: React.Dispatch<React.SetStateAction<string>>;
  listaDeCFOP: ListaDeCFOPsProps[];
  setListaDeCFOP: React.Dispatch<React.SetStateAction<ListaDeCFOPsProps[]>>;
  codigoDeBarra: string;
  setCodigoDeBarra: React.Dispatch<React.SetStateAction<string>>;
  resetModalOnClose: () => void;
  confirmarAlteracao: (fn: any) => void;
  novoProduto: NovoProduto;
  setNovoProduto: React.Dispatch<NovoProduto>;
  novoNCM: NovoNCM;
  setNovoNCM: React.Dispatch<NovoNCM>;
  novoCFOP: NovoCFOP;
  setNovoCFOP: React.Dispatch<NovoCFOP>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getStatusCodes: (itemRevalidado: ProductDataToTable) => number[];
  handleFiltrosOrigem: (origem: string) => void;
  handleFiltrosStatus: (status: string) => void;
  showData: string;
  setShowData: React.Dispatch<React.SetStateAction<string>>;
  handleAtualizaUnCompraEReferencia: () => Promise<void>;
  handleResponseData: (formatedResponse: ResponseDataProps) => Promise<void>;
};

export const ModalDeCorrecoesContext = createContext(
  {} as ModalDeCorrecoesContextData,
);

interface ValidadorXMLContextProviderProps {
  children: ReactNode;
}

export function ModalDeCorrecoesContextProvider({
  children,
}: ValidadorXMLContextProviderProps): JSX.Element {
  const {
    itemACorrigir,
    parametrosValidacao,
    dadosErpDoFornecedor,
    dadosDaCapa,
    setItensDaTabela,
    setCamposAlterados,
    returnDadosRevalidados,
  } = useValidadorXML();
  const [loading, setLoading] = useState<boolean>(false);
  // const [key, setKey] = useState('todos');
  const [filtroOrigem, setFiltroOrigem] = useState('TODOS');
  const [filtroStatus, setFiltroStatus] = useState('TODOS');
  const [dadosItemACorrigir, setDadosItemACorrigir] =
    useState<ProductDataToTable>(itemACorrigir);
  const [listaDeStatus, setListaDeStatus] = useState<number[]>([]);
  const [executando, setExecutando] = useState<ExecStatusProps>({
    1: false,
    2: false,
    3: false,
    100: false,
    101: false,
    102: false,
    103: false,
    200: false,
    201: false,
    202: false,
    300: false,
    301: false,
    302: false,
    303: false,
    304: false,
    305: false,
    306: false,
    307: false,
    308: false,
    309: false,
    310: false,
    311: false,
  });
  const [modalTab, setModalTab] = useState<string>('filtros');
  const [listaDeGtins, setListaDeGtins] = useState<ListaDeGtinsProps[]>([]);
  const [listaDeNCM, setListaDeNCM] = useState<ListaDeNCMsProps[]>([]);
  const [funcaoConfirmar, setFuncaoConfirmar] = useState<string>('');
  const [listaDeCFOP, setListaDeCFOP] = useState<ListaDeCFOPsProps[]>([]);
  // Atualizar código de barras
  const [codigoDeBarra, setCodigoDeBarra] = useState(itemACorrigir.cEAN);
  // Substituir produto
  const [novoProduto, setNovoProduto] = useState<NovoProduto>(
    {} as NovoProduto,
  );
  // Substituir NCM
  const [novoNCM, setNovoNCM] = useState<NovoNCM>({} as NovoNCM);
  const [showData, setShowData] = useState<string>('');
  // Substituir CFOP
  const [novoCFOP, setNovoCFOP] = useState<NovoCFOP>({} as NovoCFOP);

  const resetModalOnClose = useCallback(() => {
    setFiltroOrigem('TODOS');
    setFiltroStatus('TODOS');
    setModalTab('filtros');
    setCodigoDeBarra('');
    setShowData('');
  }, []);

  const getStatusCodes = useCallback(
    (itemRevalidado: ProductDataToTable): number[] => {
      return itemRevalidado.statusDaValidacao.map((status) => {
        return status.codStatus;
      });
    },
    [],
  );

  function getCamposEditados(index: string, campos: string[], metodo: string) {
    const camposEditados = campos.map((campo) => {
      return {
        index,
        campo,
        origem: metodo,
      };
    });
    return camposEditados;
  }

  const confirmarAlteracao = useCallback(
    async (funcao: any) => {
      setLoading(true);
      switch (funcao) {
        case 'substituirProduto': {
          if (!novoProduto.cod_produto) {
            toast.warn('É Necessário Selecionar um Produto.');
            setLoading(false);
            return;
          }
          const novoProdutoFormatado = await substituirProduto(
            novoProduto,
            dadosItemACorrigir,
            parametrosValidacao,
            dadosErpDoFornecedor,
            dadosDaCapa,
          );
          if (
            novoProdutoFormatado.cod_produto === '' &&
            novoProdutoFormatado.cod_gtin_principal === ''
          ) {
            toast.error('Não foi possível substituir o produto.');
            break;
          }
          setDadosItemACorrigir(novoProdutoFormatado);
          setListaDeStatus(getStatusCodes(novoProdutoFormatado));
          setItensDaTabela((prev) =>
            prev.map((item) => {
              if (item.index === novoProdutoFormatado.index) {
                return novoProdutoFormatado;
              }
              return item;
            }),
          );
          const campos = [
            'index',
            'perfil',
            'lojaUF',
            'associado',
            'cod_loja',
            'cod_fornecedor',
            'cod_gtin_principal',
            'cod_ncm',
            'cod_produto',
            'cod_similar',
            'cod_sit_tributaria_ent',
            'cod_sit_tributaria_saida',
            'cod_trib_ent_ncm',
            'cod_trib_entrada',
            'cod_trib_saida',
            'cod_id_ctb',
            'des_produto',
            'des_trib_ent',
            'des_trib_saida',
            'des_unidade_compra',
            'des_unid_compra_forn',
            'des_sigla',
            'flg_bonificado',
            'flg_fora_linha',
            'flg_gtin_vinculado',
            'flg_inativo',
            'flg_ipv',
            'flg_nao_pis_cofins',
            'flg_cfop_alterado',
            'num_cest',
            'num_ncm',
            'per_cofins',
            'per_pauta_iva_ncm',
            'per_pis',
            'qtd_embalagem_compra',
            'qtd_embalagem_compra_un_trib',
            'qtd_unid_compra_forn',
            'tipo_especie',
            'des_tipo_item',
            'tipo_nao_pis_cofins',
            'tipo_trib_ent',
            'tipo_trib_saida',
            'val_icms_ent',
            'val_icms_saida',
            'val_reducao_ent',
            'val_reducao_saida',
          ];
          const camposEditados = getCamposEditados(
            novoProdutoFormatado.index,
            campos,
            'substituirProduto',
          );
          setCamposAlterados((prev) => [...prev, ...camposEditados]);
          toast.success('Item Substituido com Sucesso!');
          setModalTab('filtros');
          break;
        }
        case 'alterarNCM': {
          if (!novoNCM.cod_ncm) {
            toast.warn('É Necessário Selecionar um NCM.');
            setLoading(false);
            return;
          }
          /** Substitui dados do NCM */
          dadosItemACorrigir.cod_trib_entrada = novoNCM.cod_trib_entrada;
          dadosItemACorrigir.des_trib_ent = novoNCM.des_trib_ent;
          dadosItemACorrigir.cod_trib_saida = novoNCM.cod_tributacao;
          dadosItemACorrigir.des_trib_saida = novoNCM.des_trib_saida;
          dadosItemACorrigir.per_pauta_iva_ncm = novoNCM.per_iva;
          dadosItemACorrigir.cod_ncm = novoNCM.cod_ncm;
          dadosItemACorrigir.num_ncm = novoNCM.num_ncm;
          const itemRevalidado = await revalidaItemUnico(
            dadosItemACorrigir,
            parametrosValidacao,
            dadosErpDoFornecedor,
            dadosDaCapa,
          );
          setListaDeStatus(getStatusCodes(itemRevalidado));
          setItensDaTabela((prev) =>
            prev.map((item) => {
              if (item.index === itemRevalidado.index) {
                return itemRevalidado;
              }
              return item;
            }),
          );
          const campos = [
            'cod_trib_entrada',
            'des_trib_ent',
            'cod_trib_saida',
            'des_trib_saida',
            'per_pauta_iva_ncm',
            'cod_ncm',
            'num_ncm',
          ];
          const camposEditados = getCamposEditados(
            itemRevalidado.index,
            campos,
            'alterarNCM',
          );
          setCamposAlterados((prev) => [...prev, ...camposEditados]);
          setModalTab('filtros');

          const promises = [];

          promises.push(
            api.put('/atualiza-ncm-produto-loja', {
              cod_loja: itemRevalidado.cod_loja,
              cod_produto: itemRevalidado.cod_produto,
              cod_ncm: itemRevalidado.cod_ncm,
              cod_trib_entrada: itemRevalidado.cod_trib_entrada,
              cod_tributacao: itemRevalidado.cod_trib_saida,
              per_iva: itemRevalidado.per_pauta_iva_ncm,
            }),
          );

          promises.push(
            api.put('/atualiza-produto-flag-pis-cofins', {
              cod_produto: itemRevalidado.cod_produto,
              flg_nao_pis_cofins: itemRevalidado.flg_nao_pis_cofins,
              tipo_nao_pis_cofins: itemRevalidado.tipo_nao_pis_cofins,
            }),
          );

          await Promise.all(promises);
          setShowData('');
          setNovoNCM({} as NovoNCM);
          toast.success('NCM Substituido com Sucesso!');
          break;
        }
        case 'alterarCFOP': {
          if (!novoCFOP.cfop) {
            toast.warn('É Necessário Selecionar um CFOP.');
            setLoading(false);
            return;
          }
          /** Substitui dados do CFOP */
          dadosItemACorrigir.cfop = novoCFOP.cfop;
          dadosItemACorrigir.cod_id_ctb = novoCFOP.cod_id_ctb;
          dadosItemACorrigir.flg_cfop_alterado = true;
          const itemRevalidado = await revalidaItemUnico(
            dadosItemACorrigir,
            parametrosValidacao,
            dadosErpDoFornecedor,
            dadosDaCapa,
          );
          setListaDeStatus(getStatusCodes(itemRevalidado));
          setItensDaTabela((prev) =>
            prev.map((item) => {
              if (item.index === itemRevalidado.index) {
                return itemRevalidado;
              }
              return item;
            }),
          );
          const campos = ['cfop', 'cod_id_ctb', 'flg_cfop_alterado'];
          const camposEditados = getCamposEditados(
            itemRevalidado.index,
            campos,
            'alterarCFOP',
          );
          setCamposAlterados((prev) => [...prev, ...camposEditados]);
          setModalTab('filtros');
          setShowData('');
          setNovoCFOP({} as NovoCFOP);
          toast.success('CFOP Substituido com Sucesso!');
          break;
        }
        default:
          break;
      }
      setLoading(false);
    },
    [
      novoProduto,
      dadosItemACorrigir,
      parametrosValidacao,
      dadosErpDoFornecedor,
      dadosDaCapa,
      getStatusCodes,
      setItensDaTabela,
      novoNCM.cod_ncm,
      novoNCM.cod_trib_entrada,
      novoNCM.des_trib_ent,
      novoNCM.cod_tributacao,
      novoNCM.des_trib_saida,
      novoNCM.per_iva,
      novoNCM.num_ncm,
      setCamposAlterados,
      novoCFOP.cfop,
      novoCFOP.cod_id_ctb,
    ],
  );

  const handleFiltrosOrigem = useCallback(
    (origem: string) => {
      const filterFn = (item: statusValidation) => {
        if (filtroStatus !== 'TODOS' && item.restricao !== filtroStatus) {
          return false;
        }
        switch (origem) {
          case 'TODOS':
            return true;
          case 'MIX':
            return item.origem === 'MIX';
          case 'CAD':
            return item.origem === 'CAD';
          case 'GTIN':
            return item.origem === 'GTIN';
          case 'TRIB':
            return item.origem === 'TRIB';
          default:
            return false;
        }
      };

      if (Array.isArray(dadosItemACorrigir.statusDaValidacao)) {
        setListaDeStatus(
          dadosItemACorrigir.statusDaValidacao
            .filter(filterFn)
            .map((item) => item.codStatus),
        );
      } else {
        setListaDeStatus([]);
      }
    },
    [dadosItemACorrigir.statusDaValidacao, filtroStatus, setListaDeStatus],
  );

  const handleResponseData = useCallback(
    async (formatedResponse: ResponseDataProps) => {
      const { response, campo, origem, callBack } = formatedResponse;
      if (response.success === false) {
        setExecutando({ ...executando, 100: false });
        toast.error(response.message);
        return;
      }
      if (typeof response.data === 'object') {
        setDadosItemACorrigir(response.data);

        if (typeof response.data.statusDaValidacao !== 'string') {
          const codStatus = response.data.statusDaValidacao.map((status) => {
            return status.codStatus;
          });

          setListaDeStatus(codStatus);
          setExecutando({ ...executando, 100: false });
          setCamposAlterados((prev) => [
            ...prev,
            {
              index: itemACorrigir.index,
              campo,
              origem,
            },
          ]);
          returnDadosRevalidados(response.data);
          if (callBack) {
            await callBack(dadosItemACorrigir.cod_produto);
          }
          toast.success(response.message);
        }
      }
    },
    [
      dadosItemACorrigir.cod_produto,
      executando,
      itemACorrigir.index,
      returnDadosRevalidados,
      setCamposAlterados,
      setDadosItemACorrigir,
      setExecutando,
      setListaDeStatus,
    ],
  );

  const handleFiltrosStatus = useCallback(
    (status: string) => {
      const filterFn = (item: statusValidation) => {
        if (filtroOrigem !== 'TODOS' && item.origem !== filtroOrigem) {
          return false;
        }
        switch (status) {
          case 'TODOS':
            return true;
          case 'INF':
            return item.restricao === 'INF';
          case 'BLOQ':
            return item.restricao === 'BLOQ' || item.restricao === 'NE';
          default:
            return false;
        }
      };

      if (Array.isArray(dadosItemACorrigir.statusDaValidacao)) {
        setListaDeStatus(
          dadosItemACorrigir.statusDaValidacao
            .filter(filterFn)
            .map((item) => item.codStatus),
        );
      } else {
        setListaDeStatus([]);
      }
    },
    [dadosItemACorrigir.statusDaValidacao, filtroOrigem, setListaDeStatus],
  );

  // Atualiza compra e ref
  const handleAtualizaUnCompraEReferencia = useCallback(async () => {
    if (
      dadosItemACorrigir.cod_gtin_principal === '' ||
      dadosItemACorrigir.cod_produto === ''
    ) {
      toast.warning('Para Atualizar a UN é Necessário Selecionar um Produto!');
      setExecutando({ ...executando, 100: false });
      return;
    }
    const revalidado = await atualizarUnidadeCompraEReferencia({
      dadosItemACorrigir,
      parametrosValidacao,
      dadosDaCapa,
      dadosErpDoFornecedor,
    });

    // retorna dados revalidador
    setDadosItemACorrigir(revalidado);

    if (typeof dadosItemACorrigir.statusDaValidacao !== 'string') {
      const codStatus = dadosItemACorrigir.statusDaValidacao.map((status) => {
        return status.codStatus;
      });
      setListaDeStatus(codStatus);
    }

    setExecutando({ ...executando, 100: false });
    setCamposAlterados((prev) => [
      ...prev,
      {
        index: itemACorrigir.index,
        campo: 'des_unidade_compra',
        origem: 'Unidade Cpra. e Ref. Atualizada com sucesso.',
      },
    ]);
    returnDadosRevalidados(revalidado);
  }, [
    dadosDaCapa,
    dadosErpDoFornecedor,
    dadosItemACorrigir,
    executando,
    itemACorrigir.index,
    parametrosValidacao,
    returnDadosRevalidados,
    setCamposAlterados,
    setDadosItemACorrigir,
    setExecutando,
    setListaDeStatus,
  ]);

  useEffect(() => {
    if (itemACorrigir.statusDaValidacao) {
      setListaDeStatus(
        itemACorrigir.statusDaValidacao.map((status) => {
          return status.codStatus;
        }),
      );
    }
    setCodigoDeBarra(itemACorrigir.cEAN);
    setDadosItemACorrigir(itemACorrigir);
  }, [itemACorrigir, setDadosItemACorrigir, setListaDeStatus]);
  /**
   * Funções
   */

  return (
    <ModalDeCorrecoesContext.Provider
      value={{
        filtroOrigem,
        setFiltroOrigem,
        filtroStatus,
        setFiltroStatus,
        dadosItemACorrigir,
        setDadosItemACorrigir,
        listaDeStatus,
        setListaDeStatus,
        executando,
        setExecutando,
        modalTab,
        setModalTab,
        listaDeGtins,
        setListaDeGtins,
        listaDeNCM,
        setListaDeNCM,
        funcaoConfirmar,
        setFuncaoConfirmar,
        listaDeCFOP,
        setListaDeCFOP,
        codigoDeBarra,
        setCodigoDeBarra,
        resetModalOnClose,
        confirmarAlteracao,
        novoProduto,
        setNovoProduto,
        loading,
        setLoading,
        novoNCM,
        setNovoNCM,
        novoCFOP,
        setNovoCFOP,
        getStatusCodes,
        handleFiltrosOrigem,
        handleFiltrosStatus,
        showData,
        setShowData,
        handleAtualizaUnCompraEReferencia,
        handleResponseData,
      }}
    >
      {children}
    </ModalDeCorrecoesContext.Provider>
  );
}

export const useModalDeCorrecoes = (): ModalDeCorrecoesContextData => {
  return useContext(ModalDeCorrecoesContext);
};
